import Humanitas from '../Pictures/Partners/Humanitas.jpg'
import Integrace from '../Pictures/Partners/Integrace.jpg'

export const Partners = [
    {
        id:1,
        name:'Humanitas Africa',
        description: 'Humanitas Afrika was founded by a handful of Africans and Czechs in Prague in 2000 to promote mutual tolerance and understanding between Czechs and Africans, and raise awareness about Africa, culture in the Czech society. Generally, it promotes and enhances good and friendly relations between Czechia and Africa, and by extension between Europe and Africa. \n In 2008, it established the African Information Centre with an African library where various African books ranging from history, politics, literature, economics culture can be borrowed by individuals or read in the library.',
        web:'https://www.humanitasafrika.cz/en/',
        thumb: Humanitas
    },
    {
        id:2,
        name: 'Integrační centrum Praha',
        description: 'Integrační centrum Praha is a non-profit organisation that strives to make Prague a city for everyone – a home to all those who have decided to live here regardless of their country of origin, and a place where the citizens respect and support one another. Their activities include consulting services, language courses, interpretation and accompaniment',
        web:'https://icpraha.com/en/who-we-are/',
        thumb: Integrace
    }
]