import React from 'react'
import { Events } from '../Data/Events'
import { Link, useParams } from 'react-router-dom'
import { Embed, Icon } from 'semantic-ui-react'
import { useMediaQuery } from 'react-responsive'
import { Image } from 'antd'

export default function EventDetails() {
    const {id} = useParams()
  const mobile = useMediaQuery({query:'(max-width:1130px)'})

 


  return (
<>
    <div className='pageBanner' style={{height:`${mobile?'65px':'90px'}`,backgroundColor:'#171717'}}>
    <div className='pageBannerText'>
      <br/>      
    </div>
  </div>
    <div id='details'>
      <br/>
      <Image alt='thumbnail' src={Events[Number(id)-1].image} style={{width:'100px'}}/>
      <h1>{Events[Number(id)-1].event}</h1>
      <h3><Icon name='calendar'/>{Events[Number(id)-1].date}</h3>   
      <h3><Icon name='clock'/>{Events[Number(id)-1].time}</h3>
      <h3><Icon name='map'/>{Events[Number(id)-1].location}</h3>
      {Events[Number(id)-1].summary}
      {Events[Number(id)-1].map?<Link to={Events[Number(id)-1].map} target='_blank'><h2><i>{Events[Number(id)-1].location}</i></h2></Link>:''}
      <br/>
      <br/>
      {Events[Number(id)-1].flyer?<a href={Events[Number(id)-1].flyer}><img alt='flyer' src={Events[Number(id)-1].flyer} style={{width:'100px'}}/></a>:<></>}
      {Events[Number(id)-1].form && Events[Number(id)-1].sort> new Date()?<div><Link to={`${Events[Number(id)-1].form}`} id='joinUs' reloadDocument target='_blank'>Sign Up</Link><br/><br/></div>:<></>}
{   Events[Number(id)-1].gallery===true?<Link id='joinUs' to={`/eventgallery/${Events[Number(id-1)].id}`} reloadDocument>Event Gallery</Link>:<></> }


    </div>
    </>
  )
}
