import { Link } from "react-router-dom"
import ContactForm from "./ContactForm"
import React from 'react'
import logo from '../Pictures/logo_low.png'
import { Icon,Divider, Image } from "semantic-ui-react"
import { Executives } from "../Data/Executives"
import { Partners } from "../Data/Partners"

export default function Contacts() {
  return (
    <div id='contact'>
      <div id='general'>
      <Divider horizontal style={{color:'white'}}><h2>Contacts</h2></Divider>

        <li><Icon name="mail"/><a href='mailto:ghanaunion.cz@gmail.com' target='_blank' rel="noreferrer">ghanaunion.cz@gmail.com</a></li>
        <li><Icon name='instagram' inverted/> <a href="https://www.instagram.com/ghanaunioncz/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==" target="_blank"rel='noreferrer'>@ghanaunioncz</a></li>
        <li><Icon name='facebook' inverted/> <a href="https://www.facebook.com/GHunioncz" target="_blank"rel='noreferrer'>GHunioncz</a></li>
        {Executives.map((exec)=>(
          <li><a href={`tel:${exec.number}`} > {`${exec.position} : ${exec.number}`}</a></li>
        ))}
      </div>
      <ContactForm/>

    <div id="webLinks">
    <Divider horizontal style={{color:'white'}}><h2>Website Links</h2></Divider>
        <li><Link reloadDocument to={'/'}>Home</Link></li>
        <li><Link reloadDocument to={'/about'}>About Us</Link></li>
        <li><Link reloadDocument to={'/events'}>Events</Link></li>
        <li><Link reloadDocument to={'/gallery'}>Gallery</Link></li>
        <li><Link to={'/joinus'} reloadDocument>Join Us</Link></li>

    </div>

<div></div>

<div id="partners-links">
  <Divider horizontal style={{color:'white'}}><h2>Partners</h2></Divider>
  <ul className="partners-list">
    <li><Link target="_blank" to={Partners[0].web}><Image centered src={Partners[0].thumb} style={{width:'150px'}}/></Link></li>
    <li><Link target="_blank" to={Partners[1].web}><Image centered src={Partners[1].thumb} style={{width:'150px'}}/></Link></li>
  </ul>
</div>

<div></div>
<div></div>

          <footer style={{textAlign:'center'}}>
            <small>Copyright © 2025 Ghana Union Czech Republic</small>
          </footer>

    </div>

    
  )
}
